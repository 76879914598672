<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <!-- <div class="column"> -->
        <div class="column scroll-x">
          <div class="is-flex block">
            <div>
              <h1 class="title">Members</h1>
              <h2 class="subtitle">
                Manage your registered members
              </h2>
            </div>
            <input
              v-model="memberSearch"
              class="input"
              type="search"
              placeholder="Search members"
              style="width: 25%"
            />
            <!--              <button @click="extendUserMemberships">extend</button>-->
          </div>
          <template v-if="admin">
            <div class="discord-all mb-4">
              <div class="is-flex is-flex-direction-column">
                <div>
                  <button @click="updateDiscordForAll" class="button is-small is-primary" :class="{ 'is-loading': updateDiscordAllLoading }" :disabled="updateDiscordAllLoading">Update Discord for all</button>
                </div>
                <span v-if="updateDiscordAllLoading" class="is-size-7">{{ updateDiscordAllCurrent }}/{{ updateDiscordAllTotal }}</span>
              </div>
            </div>
            <div class="discord-all mb-4">
              <div class="is-flex is-flex-direction-column">
                <div>
                  <button @click="compareDiscord" class="button is-small is-primary" :class="{ 'is-loading': compareDiscordLoading }" :disabled="compareDiscordLoading">Compare Discord</button>
                </div>
              </div>
            </div>
            <div v-if="discordCompareMembersNotFound.length" class="mb-4">
              <p>Users with member role on Discord not found among the site users:</p>
              <table class="table is-fullwidth is-hoverable is-narrow">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Discord ID</th>
                  <th>Joined</th>
                </tr>
                </thead>
                <tfoot>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Discord ID</th>
                  <th>Joined</th>
                </tr>
                </tfoot>
                <tbody>
                <tr v-for="(user, i) in discordCompareMembersNotFound" :key="user.id">
                  <th>{{ i + 1 }}</th>
                  <td>{{ user.user.username }}#{{ user.user.discriminator }}</td>
                  <td>{{ user.user.id }}</td>
                  <td>{{ user.joined_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>
<!--            <div class="discord-all mb-4">-->
<!--              <div class="is-flex is-flex-direction-column">-->
<!--                <div>-->
<!--                  <button @click="compareTelegram" class="button is-small is-primary" :class="{ 'is-loading': compareTelegramLoading }" :disabled="compareTelegramLoading">Compare Telegram</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="telegramCompareMembersNotFound.length" class="mb-4">-->
<!--              <p>Users with expired memberships and connected Telegram accounts:</p>-->
<!--              <table class="table is-fullwidth is-hoverable is-narrow">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th>#</th>-->
<!--                  <th>Name</th>-->
<!--                  <th>Telegram ID</th>-->
<!--                  <th>Joined</th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tfoot>-->
<!--                <tr>-->
<!--                  <th>#</th>-->
<!--                  <th>Name</th>-->
<!--                  <th>Telegram ID</th>-->
<!--                  <th>Joined</th>-->
<!--                </tr>-->
<!--                </tfoot>-->
<!--                <tbody>-->
<!--                <tr v-for="(user, i) in telegramCompareMembersNotFound" :key="user.id">-->
<!--                  <th>{{ i + 1 }}</th>-->
<!--                  <td>{{ user.user.username }}#{{ user.user.discriminator }}</td>-->
<!--                  <td>{{ user.user.id }}</td>-->
<!--                  <td>{{ user.joined_at }}</td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
            <div class="mb-2">
              <button @click="toggleShowExtendMembership" class="button is-primary">
                <template>
                  <span v-if="!showExtendMembership">Show Extend Active Memberships Tool</span>
                  <span v-else>Hide Extend Active Memberships Tool</span>
                </template>
              </button>
            </div>
            <div v-if="showExtendMembership" class="block">
              <div class="field is-grouped">
                <div class="is-flex is-align-items-center mr-2">
                  <span>Extend all active memberships by </span>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label"></div>
                  <div class="field-body">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control is-expanded">
                          <input
                              v-model.number="extendMembershipsDays"
                              class="input"
                              placeholder="days"
                              type="number"
                          />
                        </p>
                        <p class="control">
                          <a class="button is-static">
                            days
                          </a>
                        </p>
                      </div>
                      <!--                      <p class="help">Do not enter the first zero</p>-->
                    </div>
                  </div>
                </div>
                <!--                <div class="control">-->
                <!--                  <input-->
                <!--                      v-model.number="extendMembershipsDays"-->
                <!--                      class="input"-->
                <!--                      placeholder="days"-->
                <!--                      type="number"-->
                <!--                  />-->
                <!--                </div>-->
                <div class="control ml-2">
                  <button @click="extendUserMemberships" class="button is-warning" :class="{ 'is-loading': extendMembershipsLoading }" :disabled="!extendMembershipsDays">Extend</button>
                </div>
              </div>
            </div>
            <div>
              <label class="checkbox">
                <input v-model="confirmMemberDelete" type="checkbox">
                Confirm member delete
              </label>
            </div>
          </template>
          <div class="filters is-flex mb-4">
            <label class="checkbox">
              <input v-model="rowNumberFilter" type="checkbox">
              #
            </label>
            <label class="checkbox">
              <input v-model="emailFilter" type="checkbox">
              Email
            </label>
            <label class="checkbox">
              <input v-model="discordFilter" type="checkbox">
              Discord
            </label>
            <label class="checkbox">
              <input v-model="telegramFilter" type="checkbox">
              Telegram ID
            </label>
            <label class="checkbox">
              <input v-model="telegramUserNameFilter" type="checkbox">
              Telegram Username
            </label>
            <label class="checkbox">
              <input v-model="notesFilter" type="checkbox">
              Notes
            </label>
            <label class="checkbox">
              <input v-model="balanceFilter" type="checkbox">
              Balance
            </label>
            <label class="checkbox">
              <input v-model="membershipFilter" type="checkbox">
              Membership
            </label>
            <label class="checkbox">
              <input v-model="registeredFilter" type="checkbox">
              Registered
            </label>
            <label class="checkbox">
              <input v-model="codeFilter" type="checkbox">
              Code
            </label>
            <label class="checkbox">
              <input v-model="hiddenFilter" type="checkbox">
              Hidden
            </label>
            <label class="checkbox">
              <input v-model="bybitFilter" type="checkbox">
              Bybit
            </label>
            <label class="checkbox">
              <input v-model="discountFilter" type="checkbox">
              Discount
            </label>
            <label class="checkbox">
              <input v-model="whopFilter" type="checkbox">
              Whop
            </label>
            <label class="checkbox">
              <input v-model="actionsFilter" type="checkbox">
              Actions
            </label>
          </div>
          <div class="pagination">
            <div class="block">
              <div class="field is-grouped is-flex is-align-items-center">
                <div class="is-flex is-align-items-center mr-2">
                  <span>Items per page:  </span>
                </div>
                <div class="field is-horizontal mb-0">
                  <div class="field-label"></div>
                  <div class="field-body">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control is-expanded">
                          <input
                              v-model.number="tableItemsPerPage"
                              class="input"
                              placeholder="days"
                              type="number"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="is-flex is-align-items-center mx-2">
                  <span>Current page:  </span>
                </div>
                <div class="field is-horizontal mb-0">
                  <div class="field-label"></div>
                  <div class="field-body">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control is-expanded">
                          <input
                              v-model.number="tableCurrentPage"
                              class="input"
                              placeholder="days"
                              type="number"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="control ml-2">
                  <button @click="changeTablePage(tableCurrentPage - 1)" :disabled="tableCurrentPage === 1" class="button is-primary mr-2">Prev</button>
                  <button @click="changeTablePage(tableCurrentPage + 1)" :disabled="tableCurrentPage >= paginatedMembersMaxPage" class="button is-primary">Next</button>
                </div>
              </div>
            </div>
          </div>
            <div class="card block">
              <div class="card-content">
                <div class="table-container">
                  <table class="table is-fullwidth is-hoverable is-narrow">
                    <thead>
                      <tr>
                        <th v-if="rowNumberFilter">#</th>
                        <th v-if="emailFilter" @click="changeSortBy('email')">Email</th>
                        <th v-if="discordFilter" @click="changeSortBy('discord')">Discord</th>
                        <th v-if="telegramFilter" @click="changeSortBy('telegram_id')">Telegram</th>
                        <th v-if="telegramUserNameFilter" @click="changeSortBy('telegram_name')">Telegram Name</th>
                        <th v-if="notesFilter" @click="changeSortBy('notes')">Notes</th>
                        <th v-if="balanceFilter" @click="changeSortBy('balance')">Balance</th>
                        <th v-if="membershipFilter" @click="changeSortBy('membership')">Membership</th>
                        <th v-if="registeredFilter" @click="changeSortBy('registered')">Registered</th>
                        <th v-if="codeFilter" @click="changeSortBy('code')">Code</th>
                        <th v-if="hiddenFilter" @click="changeSortBy('hidden')">Hidden</th>
                        <th v-if="bybitFilter" @click="changeSortBy('bybit')">Bybit</th>
                        <th v-if="discountFilter" @click="changeSortBy('discount')">Discount</th>
                        <th v-if="whopFilter" @click="changeSortBy('whop')">Whop</th>
                        <th v-if="actionsFilter">Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th v-if="rowNumberFilter">#</th>
                        <th v-if="emailFilter" @click="changeSortBy('email')">Email</th>
                        <th v-if="discordFilter" @click="changeSortBy('discord')">Discord</th>
                        <th v-if="telegramFilter" @click="changeSortBy('telegram_id')">Telegram</th>
                        <th v-if="telegramUserNameFilter" @click="changeSortBy('telegram_name')">Telegram Name</th>
                        <th v-if="notesFilter" @click="changeSortBy('notes')">Notes</th>
                        <th v-if="balanceFilter" @click="changeSortBy('balance')">Balance</th>
                        <th v-if="membershipFilter" @click="changeSortBy('membership')">Membership</th>
                        <th v-if="registeredFilter" @click="changeSortBy('registered')">Registered</th>
                        <th v-if="codeFilter" @click="changeSortBy('code')">Code</th>
                        <th v-if="hiddenFilter" @click="changeSortBy('hidden')">Hidden</th>
                        <th v-if="bybitFilter" @click="changeSortBy('bybit')">Bybit</th>
                        <th v-if="discountFilter" @click="changeSortBy('discount')">Discount</th>
                        <th v-if="whopFilter" @click="changeSortBy('whop')">Whop</th>
                        <th v-if="actionsFilter">Actions</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr v-for="(user, i) in paginatedMembers" :key="user.id">
                        <th v-if="rowNumberFilter">{{ i + 1 }}</th>
                        <td v-if="emailFilter">{{ user.email }}</td>
                        <td v-if="discordFilter">{{ discordText(user) }}</td>
                        <td v-if="telegramFilter">{{ user.telegram_id || "" }}</td>
                        <td v-if="telegramUserNameFilter">{{ user.telegram_name || "" }}</td>
                        <td v-if="notesFilter">{{ user.notes || "" }}</td>
                        <td v-if="balanceFilter">${{ user.balance | fiat }}</td>
                        <td v-if="membershipFilter">{{ membershipText(user) }}</td>
                        <td v-if="registeredFilter">{{ user.registered | date }}</td>
                        <td v-if="codeFilter">{{ user.code }}</td>
                        <td v-if="hiddenFilter">{{ user.isHidden }}</td>
                        <td v-if="bybitFilter">{{ user.bybit_uid }}</td>
                        <td v-if="discountFilter">{{ user.discount }}</td>
                        <td v-if="whopFilter">{{ user.whopuser }}</td>
                        <td v-if="actionsFilter">
                          <div v-if="admin" class="field is-grouped">
                            <p class="control">
                              <button
                                @click="editUser(user)"
                                class="button is-small is-primary"
                              >
                                Edit
                              </button>
                            </p>
                            <p class="control">
                              <button
                                @click="deleteUser(user)"
                                class="button is-small is-danger is-light"
                                :class="{ 'is-loading': deleteUserLoading }"
                              >
                                Delete
                              </button>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div :class="{ 'is-active': editUserModal }" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div>
            <p class="modal-card-title">Edit User</p>
            <p class="is-size-6">Email: {{ selectedUser.email }}</p>
            <p class="is-size-6">Discord: {{ discordText(selectedUser) }}</p>
            <p class="is-size-6">Telegram: {{ telegramText(selectedUser) }}</p>
            <p class="has-text-weight-light is-size-7">
              Firebase uid: {{ selectedUser.id }}
            </p>
            <p @click="updateDiscordForSelectedUser()" class="has-text-weight-light is-size-7">
              Discord id: {{ selectedUser.discord_id }} (click to update)
            </p>
            <button @click="kickUserFromTelegram" class="button is-danger is-small mt-2">Kick from TG</button>
            <button v-if="selectedUser.isStaff" @click="removeFromStaff" class="button is-warning is-small mt-2">Remove from Staff</button>
            <button v-else @click="promoteToStaff" class="button is-warning is-small mt-2">Promote to Staff</button>
          </div>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <label class="label">Balance</label>
            <div class="field has-addons">
              <div class="control" style="flex: 1">
                <input
                  v-model.number="selectedUser.balance"
                  class="input"
                  type="text"
                  placeholder="User Balance"
                />
              </div>
              <p class="control">
                <a class="button is-static">
                  USD
                </a>
              </p>
            </div>

            <div class="field">
              <label class="label">Membership End Date</label>
              <div class="control">
                <input
                  v-model="selectedUser.subscription_end_date"
                  type="datetime-local"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Membership Status</label>
              <div class="control">
                <select v-model="selectedUser.status" class="input">
                  <option disabled value="">
                    Please select status
                  </option>
                  <option>
                    registered
                  </option>
                  <option>
                    active
                  </option>
                  <option>
                    expired
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">User Visibility</label>
              <div class="control">
                <select v-model="selectedUser.isHidden" class="input">
<!--                  <option disabled value="">-->
<!--                    Please select visibility-->
<!--                  </option>-->
                  <option :value="false">
                    visible
                  </option>
                  <option :value="true">
                    hidden
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Discord Numeric ID</label>
              <div class="control">
                <input
                  v-model="selectedUser.discord_id"
                  type="text"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Telegram ID</label>
              <div class="control">
                <input
                  v-model="selectedUser.telegram_id"
                  type="text"
                  class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">User Discount</label>
              <div class="control">
                <select v-model="selectedUser.discount" class="input">
                  <option :value="false">
                    disabled
                  </option>
                  <option :value="true">
                    enabled
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Bybit UID</label>
              <div class="control">
                <input
                    v-model="selectedUser.bybit_uid"
                    type="text"
                    class="input"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Whop user</label>
              <div class="control">
                <select v-model="selectedUser.whopuser" class="input">
                  <option :value="false">
                    no
                  </option>
                  <option :value="true">
                    yes
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Notes</label>
              <div class="control">
                <textarea
                  v-model.trim="selectedUser.notes"
                  class="textarea"
                  placeholder="User Notes"
                ></textarea>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            :class="{ 'is-loading': userSaveLoading }"
            @click="saveUser()"
          >
            Save
          </button>
          <button class="button is-danger" @click="editUserModal = false">
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import {auth, db} from "@/firebase";
import Firebase from 'firebase'
import AccountService from "../services/AccountService";

export default {
  name: "MembershipMembers",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      staff: false,
      memberSearch: null,
      date: new Date(),
      users: [],
      editUserModal: false,
      deleteUserLoading: false,
      selectedUser: {
        id: null,
        balance: 0,
        subscription_end_date: new Date().toISOString(),
        status: null,
        role: null,
        visibility: null,
        telegram_id: null,
        discord_id: null,
        bybit_uid: null,
        discount: null,
        whopuser: null,
        notes: "",
        isStaff: null,
        isHidden: false
      },
      userSaveLoading: false,
      rowNumberFilter: true,
      emailFilter: true,
      discordFilter: true,
      telegramFilter: true,
      telegramUserNameFilter: true,
      notesFilter: false,
      balanceFilter: true,
      membershipFilter: true,
      registeredFilter: true,
      codeFilter: true,
      hiddenFilter: true,
      bybitFilter: false,
      discountFilter: false,
      whopFilter: false,
      actionsFilter: true,
      updateDiscordAllLoading: false,
      updateDiscordAllCurrent: 0,
      updateDiscordAllTotal: 0,
      extendMembershipsDays: 0,
      showExtendMembership: false,
      extendMembershipsLoading: false,
      compareDiscordLoading: false,
      discordCompareMembersNotFound: [],
      // compareTelegramLoading: false,
      // telegramCompareMembersNotFound: [],
      tableItemsPerPage: 50,
      tableCurrentPage: 1,
      sortTableBy: "membership",
      sortTableDirection: "desc",
      confirmMemberDelete: true,
    };
  },
  firestore: {
    users: db.collection("users"),
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.staff = !!idTokenResult.claims.staff;
  },
  mounted() {
    // const calendar = new bulmaCalendar(this.$refs.trigger, {
    //   startDate: this.date,
    // })
    // calendar.on('date:selected', e => (this.date = e.start || null))
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
    fiat: function(value) {
      if (!value) return 0;
      return value.toFixed(2);
    },
  },
  computed: {
    activeMembers() {
      return this.users
        .filter((user) => user.status === "active")
        .sort((a, b) => a.subscription_end_date - b.subscription_end_date);
      // return this.users.sort((a, b) => a.subscription_end_date - b.subscription_end_date);
    },
    orderedMembers() {
      if (this.sortTableBy === "membership") {
        if (this.sortTableDirection === "asc") {
          const active_subs = this.users
              .filter((user) => user.status === "active")
              .sort((a, b) => a.subscription_end_date - b.subscription_end_date);
          const expired_subs = this.users.filter(
              (user) => user.status === "expired"
          );
          const inactive_subs = this.users.filter(
              (user) => user.status === "registered"
          );
          return [...active_subs, ...expired_subs, ...inactive_subs];
        } else {
          const active_subs = this.users
              .filter((user) => user.status === "active")
              .sort((a, b) => b.subscription_end_date - a.subscription_end_date);
          const expired_subs = this.users.filter(
              (user) => user.status === "expired"
          );
          const inactive_subs = this.users.filter(
              (user) => user.status === "registered"
          );
          return [...active_subs, ...expired_subs, ...inactive_subs];
        }
        // return this.users.sort((a, b) => a.subscription_end_date - b.subscription_end_date);
      } else if (this.sortTableBy === "balance") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => a.balance - b.balance);
        } else {
          return this.users.sort((a, b) => b.balance - a.balance);
        }
      } else if (this.sortTableBy === "discord") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a.discord_username || "").localeCompare(b.discord_username || ""));
        } else {
          return this.users.sort((a, b) => (b.discord_username || "").localeCompare(a.discord_username || ""));
        }
      } else if (this.sortTableBy === "telegram_id") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a?.telegram_id?.toString() || "").localeCompare(b?.telegram_id?.toString() || ""));
        } else {
          return this.users.sort((a, b) => (b?.telegram_id?.toString() || "").localeCompare(a?.telegram_id?.toString() || ""));
        }
      } else if (this.sortTableBy === "bybit_uid") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a?.bybit_uid?.toString() || "").localeCompare(b?.bybit_uid?.toString() || ""));
        } else {
          return this.users.sort((a, b) => (b?.bybit_uid?.toString() || "").localeCompare(a?.bybit_uid?.toString() || ""));
        }
      } else if (this.sortTableBy === "discount") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a?.discount?.toString() || "").localeCompare(b?.discount?.toString() || ""));
        } else {
          return this.users.sort((a, b) => (b?.discount?.toString() || "").localeCompare(a?.discount?.toString() || ""));
        }
      } else if (this.sortTableBy === "whop") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a?.whopuser?.toString() || "").localeCompare(b?.whopuser?.toString() || ""));
        } else {
          return this.users.sort((a, b) => (b?.whopuser?.toString() || "").localeCompare(a?.whopuser?.toString() || ""));
        }
      } else if (this.sortTableBy === "telegram_name") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a.telegram_name || "").localeCompare(b.telegram_name || ""));
        } else {
          return this.users.sort((a, b) => (b.telegram_name || "").localeCompare(a.telegram_name || ""));
        }
      } else if (this.sortTableBy === "notes") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a.notes || "").localeCompare(b.notes || ""));
        } else {
          return this.users.sort((a, b) => (b.notes || "").localeCompare(a.notes || ""));
        }
      } else if (this.sortTableBy === "registered") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => a.registered - b.registered);
        } else {
          return this.users.sort((a, b) => b.registered - a.registered);
        }
      } else if (this.sortTableBy === "code") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a.code || "").localeCompare(b.code || ""));
        } else {
          return this.users.sort((a, b) => (b.code || "").localeCompare(a.code || ""));
        }
      } else if (this.sortTableBy === "hidden") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => Number(b.isHidden || false) - Number(a.isHidden || false));
        } else {
          return this.users.sort((a, b) => Number(a.isHidden || false) - Number(b.isHidden || false));
        }
      } else if (this.sortTableBy === "email") {
        if (this.sortTableDirection === "asc") {
          return this.users.sort((a, b) => (a.email || "").localeCompare(b.email || ""));
        } else {
          return this.users.sort((a, b) => (b.email || "").localeCompare(a.email || ""));
        }
      } else {
        return this.users;
      }

    },
    filteredMembers() {
      if (!this.memberSearch) {
        if (this.staff) {
          return this.orderedMembers.filter(member => member.isHidden !== true);
        }
        return this.orderedMembers;
      } else {
        return this.orderedMembers.filter((member) => {
          let search_lowercase = this.memberSearch.toLowerCase();
          if (this.staff && member.isHidden === true) {
            return false
          }
          if (
            member.status &&
            member.status.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.email &&
            member.email.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.notes &&
            member.notes.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.discord_username &&
            member.discord_username.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else
            return !!(
              member.notes &&
              member.notes.toLowerCase().includes(search_lowercase)
            );
        });
      }
    },
    paginatedMembersMaxPage() {
      return Math.ceil(this.filteredMembers.length / this.tableItemsPerPage);
    },
    paginatedMembers() {
      // return new copy of array paginated
      return this.filteredMembers.slice(
        (this.tableCurrentPage - 1) * this.tableItemsPerPage,
        this.tableCurrentPage * this.tableItemsPerPage
      );
    },
  },
  methods: {
    changeSortBy(column) {
      if (this.sortTableBy === column) {
        this.sortTableDirection = this.sortTableDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortTableBy = column;
        this.sortTableDirection = "desc";
      }
    },
    membershipText(user) {
      if (user.subscription_end_date?.toDate() > new Date()) {
        return user.subscription_end_date?.toDate().toLocaleString();
      } else if (user.subscription_end_date?.toDate() < new Date()) {
        return "expired";
      } else {
        return "inactive";
      }
    },
    discordText(user) {
      if (user.discord_id) {
        return `${user.discord_username}#${user.discord_discriminator}`;
      } else {
        return "not connected";
      }
    },
    telegramText(user) {
      if (user.telegram_name) {
        return user.telegram_name
      } else {
        return "not connected";
      }
    },
    async updateDiscordForSelectedUser() {
      if (this.selectedUser.id) {
        try {
          const r = await this.updateDiscord(this.selectedUser.id);
          console.log(r);
          alert("Discord updated");
        } catch (e) {
          console.log(e);
          alert("Oops, an error happened.");
        }
      }
    },
    async updateDiscord(uid) {
      try {
        console.log('updating discord for user', uid)
        const r = await AccountService.updateDiscord(uid);
        console.log('discord updated for user', uid, r);
      } catch (e) {
        console.log(e);
      }
    },
    async updateDiscordForAll() {
      try {
        this.updateDiscordAllLoading = true;
        const discord = this.users.filter((user) => user.discord_id !== undefined && user.discord_access_token !== undefined);
        console.log(discord);
        this.updateDiscordAllTotal = discord.length;
        // split discord array into chunks of 20
        // const chunks = discord.reduce((resultArray, item, index) => {
        //   const chunkIndex = Math.floor(index/20)
        //   if(!resultArray[chunkIndex]) {
        //     resultArray[chunkIndex] = [] // start a new chunk
        //   }
        //   resultArray[chunkIndex].push(item)
        //   return resultArray
        // }, []);
        // console.log(chunks)
        let index = 0;
        // for await (const chunk of chunks) {
          // await this.sleep(1100)
          // console.log(index, chunk)
        // }
        for await (const user of discord) {
          index += 1;
          await this.sleep(1000)
          await this.updateDiscord(user.id)
          this.updateDiscordAllCurrent += 1;
        }
      } catch (e) {
        console.log('error happened during update all discord', e)
      } finally {
        this.updateDiscordAllCurrent = 0;
        this.updateDiscordAllTotal = 0;
       this.updateDiscordAllLoading = false;
      }
    },
    async kickUserFromTelegram() {
      try {
        if (window.confirm('Are you sure you want to kick the user?')) {
          const r = await AccountService.kickUserFromTelegram(this.selectedUser.id, this.selectedUser.telegram_id);
          console.log(r);
          alert("User kicked");
        }
      } catch (e) {
        console.log(e);
        alert("Oops, an error happened.");
      }
    },
    async promoteToStaff() {
      try {
        if (window.confirm('Are you sure you want to promote the user to Staff?')) {
          const r = await AccountService.promoteUserToStaff(this.selectedUser.id);
          this.selectedUser.isStaff = r.data.data.staff
          console.log(r);
          alert("User successfully promoted to staff");
        }
      } catch (e) {
        console.log(e);
        alert("Oops, an error happened.");
      }
    },
    async removeFromStaff() {
      try {
        if (window.confirm('Are you sure you want to remove the user from Staff?')) {
          const r = await AccountService.removeUserFromStaff(this.selectedUser.id);
          this.selectedUser.isStaff = r.data.data.staff
          console.log(r);
          alert("User successfully removed from staff");
        }
      } catch (e) {
        console.log(e);
        alert("Oops, an error happened.");
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    editUser(user) {
      this.selectedUser = { ...user };
      if (user.subscription_end_date) {
        this.selectedUser.subscription_end_date = new Date(
          user.subscription_end_date.seconds * 1000
        )
          .toISOString()
          .slice(0, 16);
      } else {
        this.selectedUser.subscription_end_date = new Date()
          .toISOString()
          .slice(0, 16);
      }
      this.editUserModal = true;
    },
    async saveUser() {
      this.userSaveLoading = true;
      await this.$firestoreRefs.users.doc(this.selectedUser.id).update({
        balance: this.selectedUser.balance,
        subscription_end_date: new Date(
          this.selectedUser.subscription_end_date
        ),
        status: this.selectedUser.status,
        role: this.selectedUser.role || "",
        isHidden: this.selectedUser.isHidden || Firebase.firestore.FieldValue.delete(),
        telegram_id: this.selectedUser.telegram_id || "",
        discord_id: this.selectedUser.discord_id || "",
        bybit_uid: this.selectedUser.bybit_uid || "",
        discount: this.selectedUser.discount || Firebase.firestore.FieldValue.delete(),
        whopuser: this.selectedUser.whopuser || Firebase.firestore.FieldValue.delete(),
        notes: this.selectedUser.notes || "",
      });
      this.userSaveLoading = false;
      this.editUserModal = false;
    },
    async extendUserMemberships() {
      // console.log("disabled");
      if (window.confirm("Are you sure you want to extend all memberships?")) {
        try {
          this.extendMembershipsLoading = true;
          for await (const user of this.activeMembers) {
              const d = user.subscription_end_date.toDate()
              d.setDate(d.getDate() + this.extendMembershipsDays)
            console.log('extending', user.email, d, user.subscription_end_date.toDate(), `${user.notes || ''}` + ` +${this.extendMembershipsDays} days on ${new Date().toLocaleString()}`)
            await this.$firestoreRefs.users.doc(user.id).update({
              subscription_end_date: d,
              notes: `${user.notes || ''}` + ` +${this.extendMembershipsDays} days on ${new Date().toLocaleString()}`,
            });
          }
        } catch (e) {
         console.log(e);
        } finally {
          this.extendMembershipsLoading = false;
        }
      }
      // this.activeMembers.forEach(user => {
      //   const d = new Date(user.subscription_end_date.seconds * 1000)
      //   d.setDate(d.getDate() + this.extendMembershipsDays)
      //   this.$firestoreRefs.users.doc(user.id).update({
      //     subscription_end_date: d
      //   })
      // })
    },
    cancelUserEdit() {
      this.selectedUser = {
        id: null,
        balance: 0,
        subscription_end_date: new Date(),
        telegram_id: null,
        discord_id: null,
        bybit_uid: null,
        notes: "",
      };
    },
    async deleteUser(user) {
      try {
        if (this.confirmMemberDelete) {
          if (window.confirm("Are you sure you want to delete the user?")) {
            this.deleteUserLoading = true;
            await AccountService.deleteUser(user.id);
          }
        } else {
          this.deleteUserLoading = true;
          await AccountService.deleteUser(user.id);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.deleteUserLoading = false;
      }
    },
    sleep(millisecond) {
      return new Promise(resolve => {
        setTimeout(() => { resolve('') }, millisecond);
      })
    },
    async toggleShowExtendMembership() {
      this.showExtendMembership = !this.showExtendMembership;
    },
    async compareDiscord() {
      try {
        this.compareDiscordLoading = true;
        this.discordCompareMembersNotFound = [];
        const membersRes = await AccountService.compareDiscord();
        const members = membersRes.data.data;
        console.log(members);
        members.forEach((member) => {
          const user = this.users.find((u) => u.discord_id === member.user.id);
          if (!user) {
            console.log('user not found on the site', member);
            this.discordCompareMembersNotFound.push(member);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.compareDiscordLoading = false;
      }
    },
    // async compareTelegram() {
    //   try {
    //     this.compareTelegramLoading = true;
    //     this.telegramCompareMembersNotFound = [];
    //     console.log("test");
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.compareTelegramLoading = false;
    //   }
    // },
    changeTablePage(page) {
      this.tableCurrentPage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  gap: 12px;
}
</style>
